<template>
  <Page class="summaryPage">
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="客户名称" prop="officeId">
          <el-select
            v-model="params.officeId"
            filterable
            clearable
            @change="officeIdChange"
          >
            <el-option
              v-for="(a, index) in officeList"
              :key="index"
              :value="a.value"
              :label="a.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号" prop="code" v-show="params.officeId">
          <el-select v-model="params.code" filterable clearable>
            <el-option
              v-for="(a, index) in deviceList"
              :key="index"
              :value="a.value"
              :label="a.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统计时间">
          <el-date-picker
            v-model="params.times"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            unlink-panels
            :clearable="false"
            :picker-options="{
              onPick: getPickDate,
              disabledDate: disabledDate
            }"
            @blur="pickDate = null"
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </template>
    <div class="main">
      <div class="summary" v-loading="summaryLoading">
        {{ summaryInfo.label || '-' }}共计有{{
          summaryInfo.total || '-'
        }}笔报障，{{ summaryInfo.finished || '-' }}笔完成
      </div>
    </div>
    <div class="areaBox">
      <!-- 客户报障占比 -->
      <EchartsCustReport
        ref="EchartsCustReport"
        v-if="!searchParams.officeId"
      />
      <!-- 设备型号占比 -->
      <EchartsDeviceStatistics ref="EchartsDeviceStatistics" v-else />
      <!-- 客户日报障统计 -->
      <EchartsCustTrend ref="EchartsCustTrend" />
    </div>
    <div class="areaBox">
      <!-- 省份报障分布情况 -->
      <EchartsProvinceStatistics ref="EchartsProvinceStatistics" />
      <!-- 故障处理方式 -->
      <EchartsTypeStatistics ref="EchartsTypeStatistics" />
    </div>
    <div class="areaBox">
      <!-- 故障报障分类 -->
      <EchartsClassStatistics
        ref="EchartsClassStatistics"
        @showDialogEvent="showDialogEvent"
      />
      <!-- 售后配件更换情况 -->
      <EchartsFittingStatistics
        ref="EchartsFittingStatistics"
        @showDialogEvent="showDialogEvent"
      />
    </div>
    <div class="areaBox">
      <!-- 报障维修设备批次 -->
      <EchartsfittingNum ref="EchartsfittingNum" />
    </div>
    <DetailTableDialog
      :parentParams="dialogParams"
      :currentItem="currentItem"
      v-if="detailVisible"
      :visible.sync="detailVisible"
    />
  </Page>
</template>

<script>
import EchartsCustReport from './components/EchartsCustReport.vue'
import EchartsCustTrend from './components/EchartsCustTrend.vue'
import EchartsProvinceStatistics from './components/EchartsProvinceStatistics.vue'
import EchartsTypeStatistics from './components/EchartsTypeStatistics.vue'
import EchartsClassStatistics from './components/EchartsClassStatistics.vue'
import EchartsFittingStatistics from './components/EchartsFittingStatistics.vue'
import EchartsfittingNum from './components/EchartsfittingNum.vue'
import EchartsDeviceStatistics from './components/EchartsDeviceStatistics.vue'
import DetailTableDialog from './components/DetailTableDialog.vue'
export default {
  components: {
    EchartsCustReport,
    EchartsCustTrend,
    EchartsProvinceStatistics,
    EchartsTypeStatistics,
    EchartsClassStatistics,
    EchartsFittingStatistics,
    EchartsfittingNum,
    EchartsDeviceStatistics,
    DetailTableDialog
  },
  data() {
    return {
      summaryLoading: false,
      detailVisible: false,
      dialogParams: {},
      currentItem: null,
      pickDate: null,
      params: {
        times: []
      },
      searchParams: {},
      summaryInfo: {},
      officeList: [],
      deviceList: [],
      //图表列表
      refNames: [
        'EchartsCustReport',
        'EchartsCustTrend',
        'EchartsProvinceStatistics',
        'EchartsTypeStatistics',
        'EchartsClassStatistics',
        'EchartsFittingStatistics',
        'EchartsfittingNum',
        'EchartsDeviceStatistics'
      ]
    }
  },
  watch: {
    'params.times.0'(newValue, oldValue) {
      this.$set(this.params, 'start', newValue)
    },
    'params.times.1'(newValue, oldValue) {
      this.$set(this.params, 'end', newValue)
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      this.getData()
      this.listOffice()
    })
    window.addEventListener('resize', this.resizeRefreshEvent, false)
  },

  methods: {
    init() {
      //初始化最近三个月
      const yesterday = new Date(new Date().valueOf() - 24 * 3600 * 1000)
      let d = this.$utils.getLastMonth(3, yesterday)
      this.params.times = [d.startTime, d.endTime]
    },
    async getData() {
      const params = this.$utils.deepClone(this.params)
      delete params.times
      this.searchParams = params
      await this.$nextTick()
      //开始请求数据
      this.getSummary(params)
      this.refNames.forEach(refName => {
        this.$refs[refName]?.getData(params)
      })
    },
    //窗口大小改变时重新渲染
    resizeRefreshEvent() {
      clearTimeout(this.timer)
      this.timer = setTimeout(this.refreshEvent, 2000)
    },
    //重新渲染图表
    refreshEvent() {
      console.log('重新渲染')
      this.refNames.forEach(refName => {
        this.$refs[refName]?.drawEcharts()
      })
    },
    getPickDate(pickDate) {
      this.pickDate = pickDate
    },
    //时间范围不能选择超过一年
    disabledDate(date) {
      const { minDate, maxDate } = this.pickDate || {}
      if (minDate && !maxDate) {
        const diff = Math.abs(minDate.valueOf() - date.valueOf())
        if (diff > 365 * 24 * 3600 * 1000) {
          return true
        }
      }
      return false
    },
    async getSummary(params) {
      try {
        this.summaryLoading = true
        const res = await this.$api.workOrderStatistics.summary(params)
        this.summaryInfo = res
      } catch (error) {
        console.log(error)
      } finally {
        this.summaryLoading = false
      }
    },
    async listOffice() {
      const list = await this.$api.workOrderStatistics.listOffice()
      this.officeList = list || []
    },
    officeIdChange() {
      this.deviceList = []
      this.$set(this.params, 'code', '')
      if (this.params.officeId) {
        this.listDevice()
      }
    },
    async listDevice() {
      const { officeId } = this.params
      const list = await this.$api.workOrderStatistics.listDevice({ officeId })
      this.deviceList = list || []
    },
    //显示详情明细弹框
    showDialogEvent(data = {}, type = 0) {
      this.dialogParams = { ...this.searchParams, type, value: data.code }
      this.currentItem = data
      this.detailVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.summaryPage {
  /deep/ .page-content {
    padding-top: 0;
  }
}
.summary {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: red;
}
.areaBox {
  display: flex;
  height: 300px;
  .echartsBox + .echartsBox {
    margin-left: 20px;
  }
}
.areaBox + .areaBox {
  margin-top: 20px;
}
</style>
