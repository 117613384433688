//报障维修设备批次
<template>
  <el-card
    class="echartsBox"
    style="height: 100%; width: 100%"
    :body-style="{ width: ' 100%', height: '100%', boxSizing: 'border-box' }"
    v-loading="loading"
  >
    <div
      class="echarts"
      ref="echarts"
      style="width: 100%; height: 100%"
      v-show="list.length"
    ></div>
    <el-empty
      :description="`${title}数据为空`"
      v-show="list.length === 0"
    ></el-empty>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      title: '报障维修设备批次(设备生产日期)',
      list: [],
      xAxisData: [],
      seriesData: []
    }
  },
  methods: {
    async getData(params) {
      try {
        this.loading = true
        const list =
          await this.$api.workOrderStatistics.fittingNumWorkOrderStatistics(
            params
          )
        this.list = list || []
      } catch (error) {
        this.list = []
        console.log(error)
      } finally {
        this.$nextTick(() => {
          this.handleData()
          this.drawEcharts()
        })
        this.loading = false
      }
    },
    //处理数据
    handleData() {
      this.xAxisData = this.list.map(a => {
        return a.label
      })
      const data = this.list.map(a => a.total)
      this.seriesData = [{ name: '记录数', type: 'bar', barMaxWidth: 30, data }]
    },
    //画图
    drawEcharts() {
      const { xAxisData, seriesData, list } = this
      this.echarts.init(this.$refs.echarts).dispose()
      if (list.length === 0) return
      var myChart = this.echarts.init(this.$refs.echarts)
      const option = {
        color: ['#009688'],
        title: {
          text: this.title,
          left: 'center'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          left: 'center',
          bottom: 0
        },
        grid: {
          left: '3%',
          right: '4%',
          top: 40,
          bottom: 35,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: seriesData
      }
      // 绘制图表
      myChart.setOption(option)
    }
  },
  beforeDestroy() {
    this.echarts.init(this.$refs.echarts).dispose()
  }
}
</script>
