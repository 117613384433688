//客户日报障统计
<template>
  <el-card
    class="echartsBox"
    style="height: 100%; width: 100%"
    :body-style="{ width: ' 100%', height: '100%', boxSizing: 'border-box' }"
    v-loading="loading"
  >
    <div
      class="echarts"
      ref="echarts"
      style="width: 100%; height: 100%"
      v-show="list.length"
    ></div>
    <el-empty
      description="客户报障统计数据为空"
      v-show="list.length === 0"
    ></el-empty>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      list: [],
      xAxisData: [],
      seriesData: []
    }
  },
  methods: {
    async getData(params) {
      try {
        this.loading = true
        const list = await this.$api.workOrderStatistics.officeWorkOrderTrend(
          params
        )
        this.list = list || []
      } catch (error) {
        this.list = []
        console.log(error)
      } finally {
        this.$nextTick(() => {
          this.handleData()
          this.drawEcharts()
        })
        this.loading = false
      }
    },
    //处理数据
    handleData() {
      this.xAxisData = this.list.map(a => {
        return a.label
      })
      this.seriesData = ['报障数', '完成数'].map((name, index) => {
        const data = this.list.map(a => (index === 0 ? a.total : a.finished))
        return { name, type: 'line', symbol: 'none', data }
      })
    },
    //画图
    drawEcharts() {
      const { xAxisData, seriesData, list } = this
      this.echarts.init(this.$refs.echarts).dispose()
      if (list.length === 0) return
      let myChart = this.echarts.init(this.$refs.echarts)
      let l = xAxisData[0].length //x轴数据
      let xAxisName = l < 4 ? '周' : l < 9 ? '月' : l >= 10 ? '日' : ''
      let title = `客户${xAxisName}报障统计`
      const option = {
        color: ['#ff6600', '#009688'],
        title: {
          text: title,
          left: 'center'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          left: 'center',
          bottom: 0
        },
        grid: {
          left: '3%',
          right: '5%',
          top: 40,
          bottom: 35,
          containLabel: true
        },
        xAxis: {
          name: xAxisName,
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: seriesData
      }
      // 绘制图表
      myChart.setOption(option)
    }
  },
  beforeDestroy() {
    this.echarts.init(this.$refs.echarts).dispose()
  }
}
</script>
