<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="`${currentItem.name}${
      parentParams.type === 0 ? '报障' : '配件更换'
    }明细表`"
    :visible.sync="show"
    width="90%"
    append-to-body
  >
    <el-table border :data="tableData" v-loading="loading">
      <el-table-column
        header-align="center"
        align="center"
        show-overflow-tooltip
        label="关联工单号"
        width="150"
        :formatter="({ workOrderCode }) => workOrderCode || '-'"
      />
      <el-table-column
        header-align="center"
        align="center"
        label="省份"
        width="150"
        show-overflow-tooltip
        :formatter="({ provinceName }) => provinceName || '-'"
      />
	  <el-table-column
	    header-align="center"
	    align="center"
	    label="客户名称(门店)"
	    width="150"
	    show-overflow-tooltip
	    :formatter="({ storeName }) => storeName || '-'"
	  />
      <el-table-column
        header-align="center"
        align="center"
        label="机器序列号"
        show-overflow-tooltip
        :formatter="({ sn }) => sn || '-'"
      />
      <el-table-column
        header-align="center"
        align="center"
        show-overflow-tooltip
        label="报障日期"
        width="160"
        :formatter="({ workCreateTime }) => workCreateTime || '-'"
      />
      <el-table-column
        header-align="center"
        align="center"
        label="故障类型"
        show-overflow-tooltip
        :formatter="({ workFaultName }) => workFaultName || '-'"
      />
	  <el-table-column
	    header-align="center"
	    align="center"
	    label="故障描述"
	    show-overflow-tooltip
	    :formatter="({ description }) => description || '-'"
	  />
      <el-table-column
        header-align="center"
        align="center"
        label="处理人员"
        show-overflow-tooltip
        :formatter="({ workSolveUserName }) => workSolveUserName || '-'"
      />
      <el-table-column
        header-align="center"
        align="center"
        label="处理方式"
        show-overflow-tooltip
        :formatter="({ workSolveWayStr }) => workSolveWayStr || '-'"
      />
	  <el-table-column
	    header-align="center"
	    align="center"
	    label="解决办法"
	    show-overflow-tooltip
	    :formatter="({ solveMethod }) => solveMethod || '-'"
	  />
	  <el-table-column
	    header-align="center"
	    align="center"
	    label="更换方式"
	    show-overflow-tooltip
	    :formatter="({ replaceModeStr }) => replaceModeStr || '-'"
	  />
	  <el-table-column
	    header-align="center"
	    align="center"
	    label="更换数量"
	    show-overflow-tooltip
	    :formatter="({ replaceCount }) => replaceCount || '0'"
		v-if="parentParams.type === 0"
	  />
      <el-table-column
        header-align="center"
        align="center"
        label="更换配件"
        show-overflow-tooltip
        :formatter="({ replaceName }) => replaceName || '-'"
		v-if="parentParams.type === 1"
      />
    </el-table>
    <span slot="footer" class="dialog-footer">
      <Pagination
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.pageNum"
        @change="getData"
      />
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: {
    //type 0-故障类型；1-设备更换
    parentParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      tableData: [],
      params: {
        ...this.parentParams,
        pageSize: 20,
        pageNum: 1
      },
      total: 0
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const res =
          await this.$api.workOrderStatistics.workOrderStatisticsDetails(
            this.params
          )
        this.tableData = (res.list || []).map(a => {
          a.workSolveWayStr =
            this.$constant.workSolveWay.find(b => b.value === a.workSolveWay)
              ?.label || a.workSolveWay
		  a.replaceModeStr = this.$constant.replaceMode.find(b => b.value === a.replaceMode)
              ?.label || a.replaceMode
          return a
        })
        this.total = res.total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
