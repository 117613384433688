//设备型号占比
<template>
  <el-card
    class="echartsBox"
    style="height: 100%; width: 100%"
    :body-style="{ width: ' 100%', height: '100%', boxSizing: 'border-box' }"
    v-loading="loading"
  >
    <div
      class="echarts"
      ref="echarts"
      style="width: 100%; height: 100%"
      v-show="list.length"
    ></div>
    <el-empty
      :description="`${title}数据为空`"
      v-show="list.length === 0"
    ></el-empty>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      title: '设备型号占比',
      list: [],
      seriesData: []
    }
  },
  methods: {
    async getData(params) {
      try {
        this.loading = true
        const list =
          await this.$api.workOrderStatistics.deviceWorkOrderStatistics(params)
        this.list = list || []
      } catch (error) {
        this.list = []
        console.log(error)
      } finally {
        this.$nextTick(() => {
          this.handleData()
          this.drawEcharts()
        })
        this.loading = false
      }
    },
    //处理数据
    handleData() {
      this.seriesData = this.list.map(a => {
        return { name: a.label, value: a.total }
      })
    },
    //画图
    drawEcharts() {
      const { seriesData, list } = this
      this.echarts.init(this.$refs.echarts).dispose()
      if (list.length === 0) return
      var myChart = this.echarts.init(this.$refs.echarts)
      const option = {
        title: {
          text: this.title,
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          type: 'scroll',
          left: 'center',
          bottom: 0
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            top: 30,
            bottom: 22,
            label: {
              normal: {
                show: true,
                color: 'auto',
                formatter: '{b}：{d}%'
              }
            },
            data: seriesData
          }
        ]
      }
      // 绘制图表
      myChart.setOption(option)
    }
  },
  beforeDestroy() {
    this.echarts.init(this.$refs.echarts).dispose()
  }
}
</script>
